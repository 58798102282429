<script setup lang="ts">
import { QuestionCircleIcon } from '@cfgtech/icons'
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import SanitizedHTML from '~/components/base/SanitizedHTML.vue'
import type { SbBenefitItemStoryblok } from '~/types/storyblok'

const props = withDefaults(defineProps<{
  blok: SbBenefitItemStoryblok
  isLastElement?: boolean
}>(), {
  isLastElement: false,
})

const renderedTitle = computed(() => renderRichText(props.blok.title))

const renderedDescription = computed(() => renderRichText(props.blok.description))
</script>

<template>
  <li>
    <div v-editable="blok" class="flex flex-col items-center justify-start gap-y-4">
      <div class="size-[48px] shrink-0 rounded-full border border-white bg-white">
        <LazyNuxtPicture
          v-if="blok.icon?.filename"
          :alt="blok.icon.alt || 'icon'"
          height="46"
          :img-attrs="{
            class: 'size-full rounded-full object-cover',
          }"
          provider="storyblok"
          :src="blok.icon?.filename"
          width="46"
        />

        <QuestionCircleIcon v-else class="text-[48px] text-grey-300" />
      </div>

      <div class="space-y-2 px-6 text-center">
        <SanitizedHTML
          v-if="renderedTitle"
          by-tag-name="h3"
          :html="renderedTitle"
        >
          <template #default="{ html }">
            <CfgTypography
              class="!font-highlighted"
              :html="html"
              :size="CfgTypographySizes.subtitle"
              tag-name="h3"
            />
          </template>
        </SanitizedHTML>

        <CfgTypography
          v-if="blok.description"
          :html="renderedDescription"
          :size="CfgTypographySizes.md"
          tag-name="div"
        />
      </div>
    </div>
  </li>
</template>
